import React from 'react';
import { Route } from 'react-router-dom';

import Page from '../common/Page';
import Claim from './Claim';
import { ItemsProvider } from '../items/ItemsContext';

const Claims = ({ match }) => (
  <Page>
    <ItemsProvider>
      <Route component={Claim} path={`${match.path}/:claimId`} />
    </ItemsProvider>
  </Page>
);

export default Claims;
