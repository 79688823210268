import React from 'react';
import styled from 'styled-components';

import { colours } from '../constants/css';
import Address from '../common/Address';

const borderColours = {
  AUTHORISED_PARTY: colours.lightBlue,
  BROKER: colours.lightBlue,
  INSURED: colours.lightBlue,
  INSURER: colours.purple,
};

const typeDisplay = {
  AUTHORISED_PARTY: 'Authorised Party',
  BROKER: 'Broker',
  INSURED: 'Insured',
  INSURER: 'Insurer',
};

const contactMethods = [
  {
    key: 'home',
    prefix: 'tel',
    symbol: 'H',
  },
  {
    key: 'work',
    prefix: 'tel',
    symbol: 'W',
  },
  {
    key: 'mobile',
    prefix: 'tel',
    symbol: 'M',
  },
  {
    key: 'email',
    prefix: 'mailto',
    symbol: 'E',
  },
];

const Container = styled.div`
  border-color: ${(props) => borderColours[props.type]};
  border-radius: 10px;
  border-style: solid;
  border-width: ${(props) => props.isPrimary ? '2px' : '1px'};
  display: flex;
  overflow-y: auto;
  padding: 3px;
  padding-left: 15px;
  position: relative;
`;

const Left = styled.div`
  max-width: 50%;
`;

const Right = styled.div`
  margin-left: 5px;
  text-align: left;
`;

const Symbol = styled.span`
  color: ${(props) => props.preferred ? colours.success : colours.lightGrey};
  padding-right: 3px;
`;

const Contact = ({ contact }) => {
  const validContacts = contactMethods.filter(({ key }) => contact[key]);

  return (
    <Container isPrimary={contact.isPrimary} type={contact.type}>
      <Left>
        <div>
          <b>{contact.firstName ? `${contact.firstName} ${contact.surname}` : contact.company}</b>
        </div>
        <div>
          <small><i>{typeDisplay[contact.type]}</i></small>
        </div>
        {contact.location && <Address location={contact.location} />}
      </Left>
      <Right>
        {validContacts.length ? (
          validContacts.map(({ key, prefix, symbol }) => (
            <div key={key}>
              <Symbol preferred={key === contact.preferredContactMethod}>{symbol}</Symbol>
              <a href={`${prefix || 'tel'}:${contact[key]}`}>{contact[key]}</a>
            </div>
          ))
        ) : (
          <div>No contact methods</div>
        )}
      </Right>
    </Container>
  );
};

export default Contact;
