/* eslint-disable react/no-multi-comp */
import React, { useState, useCallback } from 'react';
// import styled from 'styled-components';

import SpeechBubble from '../../common/SpeechBubble';
// import Address from '../../common/Address';
// import css from '../../constants/css';
// import OptionButtons from '../OptionButtons';
import { currency } from '../../utils/formatters';
import ClickToEdit from '../../common/ClickToEdit';

const INITIAL = 'initial';
const Initial = ({ excess, limits, updateClaim }) => {
  const [newExcess, setNewExcess] = useState(excess);
  const [newLimits, setNewLimits] = useState(limits);

  const updateLimit = useCallback((newValue) => {
    const updatedLimits = { ...limits, general: newValue };
    setNewLimits(updatedLimits);
    updateClaim(updatedLimits);
  }, [limits, setNewLimits, updateClaim]);

  const updateExcess = useCallback((newValue) => {
    setNewExcess(newValue);
    updateClaim({ excess: newValue });
  }, [setNewExcess, updateClaim]);

  return (
    <>
      <p>Thanks for that!</p>
      <p>So, we have been asked to assist with the jewellery portion of your claim.</p>
      <p>
        I can see your policy limit for jewellery is listed here as&nbsp;
        <ClickToEdit
          formatter={currency}
          save={updateLimit}
          type="number"
          value={newLimits.general}
        /> and your excess is&nbsp;
        <ClickToEdit
          formatter={currency}
          save={updateExcess}
          type="number"
          value={newExcess}
        />, is that correct?
      </p>
    </>
  );
};

const ConfirmPolicyScript = ({ values: { claim, me, updateClaim } }) => {
  const [subStage/* , setSubStage */] = useState(INITIAL);

  // const goToScript = useCallback((newSubStage, func) => () => {
  //   func && func();
  //   setSubStage(newSubStage);
  // }, []);

  return (
    <div>
      {{
        [INITIAL]: (
          <>
            <SpeechBubble sender={me.name} shadow side="left">
              <Initial
                excess={claim.excess}
                limits={claim.limits}
                updateClaim={updateClaim}
              />
            </SpeechBubble>
            <p>How many jewellery items were you claiming approximately?</p>
            <div><input step="1" type="number" /></div>
            <p>What is the approximate value?</p>
            <div>$<input step="0.01" type="number" /></div>
          </>
        ),
      }[subStage]}
    </div>
  );
};

export default ConfirmPolicyScript;
