/* eslint-disable id-length, object-curly-newline */
import React, { useState, useCallback } from 'react';
import RGL, { WidthProvider } from 'react-grid-layout';

import ClaimsList from './ClaimList';
import Page from '../common/Page';

const ReactGridLayout = WidthProvider(RGL); // eslint-disable-line new-cap

const Dashboard = () => {
  const [layout, setLayout] = useState([]);

  const onLayoutChange = useCallback((newLayout) => {
    setLayout(newLayout);
  }, [setLayout]);

  return (
    <Page>
      <ReactGridLayout
        autoSize={false}
        className="layout" // eslint-disable-line react/forbid-component-props
        cols={12}
        isResizable
        layout={layout}
        onLayoutChange={onLayoutChange}
      >
        <div
          // eslint-disable-next-line sort-keys
          data-grid={{ h: 4, w: 6, x: 0, y: 0, static: true }}
          key="claims-list"
          style={{ overflowY: 'scroll' }}
        >
          <ClaimsList />
        </div>
        <div
          data-grid={{ h: 2, w: 1, x: 6, y: 0 }}
          key="b"
          style={{ border: '5px solid red' }}
        >
          Coming Soon...
        </div>
        <div
          data-grid={{ h: 1, w: 1, x: 7, y: 0 }}
          key="c"
          style={{ border: '5px solid blue' }}
        >
          Coming Soon...
        </div>
      </ReactGridLayout>
    </Page>
  );
};

export default Dashboard;
