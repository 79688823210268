import React, { useCallback } from 'react';
import DatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';

const DateSelect = ({ currentDate, format = 'yyyy-MM-dd', selectDate }) => {
  const onChange = useCallback((date) => selectDate(date), [selectDate]);

  return (
    <DatePicker
      className="form-control" // eslint-disable-line react/forbid-component-props
      dateFormat={format}
      onChange={onChange}
      selected={currentDate}
    />
  );
};

export default DateSelect;
