import React, { useMemo, useCallback } from 'react';
import styled from 'styled-components';

import { colours } from '../constants/css';
import TextCircle from './styled/TextCircle';

const LINE_HEIGHT = 4;
const CIRCLE_SIZE = 30;

const Container = styled.div`
  display: flex;
  margin: 20px;
`;

const LabelContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: calc(${CIRCLE_SIZE}px + 10px);
  white-space: nowrap;
`;

const NumberCircle = styled(TextCircle)`
  cursor: pointer;
`;

const Line = styled.div`
  flex-grow: 1;
  height: ${LINE_HEIGHT}px;
  max-width: 200px;
  margin-top: ${(CIRCLE_SIZE - LINE_HEIGHT) / 2}px;
  background-color: ${(props) => props.backgroundColour};
`;

const ProgressCounter = ({ current, goToStage, stages }) => {
  const stageNames = useMemo(() => stages.map(({ key }) => key), [stages]);
  const currentIndex = useMemo(() => stageNames.indexOf(current.key), [current.key, stageNames]);
  const backgroundColours = useMemo(
    () => stageNames.map((_, index) => index > currentIndex ? colours.inactive : colours.success),
    [currentIndex, stageNames],
  );

  const onClick = useCallback(
    (key) => () => goToStage(stages[stageNames.indexOf(key)]),
    [goToStage, stages, stageNames],
  );

  return (
    <Container>
      {stages.map(({ key, label }, index) => (
        <React.Fragment key={key}>
          {Boolean(index) && <Line backgroundColour={backgroundColours[index]} />}
          <LabelContainer onClick={onClick(key)}>
            <NumberCircle backgroundColour={backgroundColours[index]} diameter={CIRCLE_SIZE}>
              {index + 1}
            </NumberCircle>
            <div>{label}</div>
          </LabelContainer>
        </React.Fragment>
      ))}
    </Container>
  );
};

export default ProgressCounter;
