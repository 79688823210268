import React from 'react';
import Form from 'react-bootstrap/Form';
import { titleCase } from '../utils/formatters';

const CoverTypeSelect = ({ children, onChange, limitTypes, value }) => (
  <Form.Control
    as="select"
    onChange={onChange}
    // ref={ref}
    value={value}
  >
    {children}
    {limitTypes.map((limitType) => (
      <option key={limitType} value={limitType}>{titleCase(limitType)}</option>
    ))}
  </Form.Control>
);

export default CoverTypeSelect;
