export const colours = {
  claimBackground: '#DEE5EB',
  darkGrey: '#CCC',
  faintBlue: '#A5D2F2',
  inactive: 'lightgrey',
  lightBlue: '#66CCFF',
  lightGrey: '#F2F2F2',
  purple: '#93C',
  success: '#6ED890',
  successInactive: '#A6DFBE',
};

export const layoutSizing = {
  header: '100px',
  tabs: '35px',
};
