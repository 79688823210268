import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
// import 'bootstrap/dist/css/bootstrap.min.css';

import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { ApolloClient } from 'apollo-client';
import { setContext } from 'apollo-link-context';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { HttpLink } from 'apollo-link-http';
import { ApolloProvider } from '@apollo/react-hooks';

import './index.scss';
import './App.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { AuthProvider, useAuth } from './auth/auth0';
import { TabsProvider } from './common/TabsContext';

const authConfig = {
  'clientId': 'cbh9l94HHABNcU0GiL10SVJ0UhFasz6E',
  'domain': 'claimsportal.au.auth0.com',
};

const InjectedApolloProvider = ({ children }) => {
  const { token } = useAuth();

  const cache = new InMemoryCache();
  const link = new HttpLink({ uri: 'http://localhost:3001/graphql' });
  const authLink = setContext((_, { headers }) => ({
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  }));

  const client = new ApolloClient({ cache, link: authLink.concat(link) });

  return (
    <ApolloProvider client={client}>
      {children}
    </ApolloProvider>
  );
};

const onRedirectCallback = (appState) => {
  window.history.replaceState(
    {},
    document.title,
    appState && appState.targetUrl
      ? appState.targetUrl
      : window.location.pathname
  );
};

ReactDOM.render(
  <BrowserRouter>
    <AuthProvider
      client_id={authConfig.clientId}
      domain={authConfig.domain}
      onRedirectCallback={onRedirectCallback}
      redirect_uri={window.location.origin}
    >
      <InjectedApolloProvider>
        <TabsProvider>
          <App />
        </TabsProvider>
      </InjectedApolloProvider>
    </AuthProvider>
  </BrowserRouter>,
  document.getElementById('root')
);

/*
 * If you want your app to work offline and load faster, you can change
 * unregister() to register() below. Note this comes with some pitfalls.
 * Learn more about service workers: https://bit.ly/CRA-PWA
 */
serviceWorker.unregister();
