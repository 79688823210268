/* eslint-disable react/no-multi-comp */
import React, { useState, useCallback } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhoneSquareAlt, faEnvelopeSquare } from '@fortawesome/free-solid-svg-icons';

import SpeechBubble from '../../common/SpeechBubble';
import Address from '../../common/Address';
import IconContainer from '../../common/styled/IconContainer';
import { colours } from '../../constants/css';
import OptionButtons from '../OptionButtons';

const INITIAL = 'initial';
const Initial = ({ insured, insurer, me }) => (
  <>
    <p>Good afternoon <strong>{insured.name}</strong>,</p>
    <p>
      I am <strong>{me.firstName}</strong> from IVAA. We have been appointed
      by <strong>{insurer.name}</strong> to assist with processing your jewellery claim.
    </p>
    <p>Is now a convenient time to talk?</p>
  </>
);

const TIME_TO_TALK = 'time-to-talk';
const TimeToTalk = () => (
  <>
    <p>Great!</p>
    <p>
      Before we begin, I've just got to let you know
      that <strong>all of our calls are recorded</strong>.
    </p>
    <p>
      Could I also please <strong>confirm your suburb and postcode</strong> for security purposes?
    </p>
  </>
);

const AddressNotConfirmed = () => (
  <>
    <p>Sorry! That's not what I've got listed here.</p>
    <p>Could you have another address saved with your insurer?</p>
  </>
);

const NO_TIME_TO_TALK = 'no-time-to-talk';
const NoTimeToTalk = () => (
  <>
    <p>No worries!</p>
    <p>
      You are welcome to call back on 07 3180 1667 (the number showing on your phone), or I can call
      you tomorrow?
    </p>
  </>
);

const MarginedAddress = styled(Address)`
  margin: 20px;
  margin-left: 50px;
`;

const IdentifyScript = ({ goToNextStage, values: { insured, insurer, location, me } }) => {
  const [subStage, setSubStage] = useState(INITIAL);
  const [contactMethod, setContactMethod] = useState(false);
  const [addressWrong, setAddressWrong] = useState(false);

  const goToScript = useCallback((newSubStage, func) => () => {
    func && func();
    setSubStage(newSubStage);
  }, []);
  const showContactPrompt = useCallback(
    (type) => () => setContactMethod(contactMethod === type ? false : type),
    [contactMethod]
  );
  const addressCheck = useCallback((correct) => () => setAddressWrong(!correct), []);

  const emailPreferred = insured.preferredContactMethod === 'email';

  return (
    <div>
      <IconContainer>
        <FontAwesomeIcon
          color={emailPreferred ? colours.inactive : colours.success}
          icon={faPhoneSquareAlt}
          onClick={showContactPrompt('phone')}
        />
        <FontAwesomeIcon
          color={emailPreferred ? colours.success : colours.inactive}
          icon={faEnvelopeSquare}
          onClick={showContactPrompt('email')}
        />
      </IconContainer>
      {contactMethod === 'email' && <div>email</div>}
      {contactMethod === 'phone' && (
        <div>
          {{
            [INITIAL]: (
              <>
                <SpeechBubble sender={me.name} shadow side="left">
                  <Initial
                    insured={insured}
                    insurer={insurer}
                    me={me}
                  />
                </SpeechBubble>
                <OptionButtons
                  onClickFactory={goToScript}
                  options={[
                    {
                      key: TIME_TO_TALK,
                      text: 'Yes!',
                    },
                    {
                      key: NO_TIME_TO_TALK,
                      text: 'No',
                    },
                    {
                      key: INITIAL,
                      text: 'No Answer',
                    },
                  ]}
                />
              </>
            ),
            [TIME_TO_TALK]: (
              <>
                <SpeechBubble sender={me.name} shadow side="left">
                  <TimeToTalk />
                </SpeechBubble>
                <MarginedAddress location={location} />
                {addressWrong && (
                  <SpeechBubble sender={me.name} shadow side="left">
                    <AddressNotConfirmed />
                  </SpeechBubble>
                )}
                <OptionButtons
                  onClickFactory={goToScript}
                  options={[
                    {
                      func: goToNextStage,
                      key: INITIAL,
                      text: 'Correct',
                    },
                    {
                      func: addressCheck(false),
                      key: TIME_TO_TALK,
                      text: 'Incorrect',
                    },
                    {
                      key: INITIAL,
                      text: 'Unauthorised Party',
                    },
                  ]}
                />
              </>
            ),
            [NO_TIME_TO_TALK]: (
              <>
                <SpeechBubble sender={me.name} shadow side="left">
                  <NoTimeToTalk />
                </SpeechBubble>
                <div>TO DO: callback options</div>
              </>
            ),
          }[subStage]}
        </div>
      )}
    </div>
  );
};

export default IdentifyScript;
