/* eslint-disable react/no-multi-comp */
import React, { useCallback } from 'react';
import { NavLink } from 'react-router-dom';

import { useTabs } from './TabsContext';

const TabLink = ({ path, text }) => {
  const { addTab } = useTabs();
  const onClick = useCallback(() => addTab({ path, text }), [addTab, path, text]);

  return (
    <NavLink onClick={onClick} to={path}>
      {text}
    </NavLink>
  );
};

const Claim = ({ claimId }) => <TabLink path={`/claims/${claimId}`} text={`#${claimId}`} />;
const Insurers = ({ text = 'Insurers' }) => <TabLink path="/insurers" text={text} />;
const Suppliers = ({ text = 'Suppliers' }) => <TabLink path="/suppliers" text={text} />;

export default {
  Claim,
  Insurers,
  Suppliers,
};
