import styled from 'styled-components';

const IconContainer = styled.div`
  font-size: 3rem;
  height: 52px;

  svg {
    margin: 5px;
    cursor: pointer;
  }
`;

export default IconContainer;
