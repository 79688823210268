import React from 'react';
import styled from 'styled-components';
import TextCircle from './styled/TextCircle';
import { nameToAbbr } from '../utils/formatters';

const Container = styled.div`
  display: flex;
`;

const InitialCircle = styled(TextCircle)`
  order: ${(props) => props.side === 'left' ? '0' : '2'};
  align-self: flex-end;
`;

const CORNERS = 20;

const TextBox = styled.div`
  ${(props) => props.shadow ? 'box-shadow: rgba(0, 0, 0, 0.35) 3px 3px 5px;' : ''}
  border-radius: 20px;
  border-bottom-right-radius: ${(props) => props.side === 'right' ? 0 : CORNERS}px;
  border-bottom-left-radius: ${(props) => props.side === 'left' ? 0 : CORNERS}px;
  order: 1;
  padding: 20px;
  white-space: pre-wrap;
  margin: 10px;
`;

const SpeechBubble = ({ children, side = '', sender = '', shadow }) => (
  <Container>
    {sender && (
      <InitialCircle
        backgroundColour="blue"
        diameter={40}
        side={side}
      >
        {nameToAbbr(sender)}
      </InitialCircle>
    )}
    <TextBox shadow={shadow} side={side}>
      {children}
    </TextBox>
  </Container>
);

export default SpeechBubble;
