import React, { useRef, useCallback } from 'react';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import Form from 'react-bootstrap/Form';

const ALL_STATUSES = gql`
  query AllStatuses {
    allStatuses {
      id
      name
    }
  }
`;

const StatusSelect = ({ currentStatus, selectStatus }) => {
  const { loading, data } = useQuery(ALL_STATUSES);
  const ref = useRef(null);

  const onChange = useCallback(() => selectStatus(ref.value), [selectStatus]);

  return loading ? 'Loading...' : (
    <Form.Control
      as="select"
      onChange={onChange}
      ref={ref}
      value={currentStatus}
    >
      {data.allStatuses.map((status) => (
        <option key={status.id} value={status}>{status.name}</option>
      ))}
    </Form.Control>
  );
};

export default StatusSelect;
