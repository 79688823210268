import React from 'react';

const Header = () => (
  <div
    style={{
      height: '100px', // update layout/Page if changing this value
      width: '100%',
    }}
  >
    This is a header
  </div>
);

export default Header;
