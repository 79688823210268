import React, { useCallback } from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import styled from 'styled-components';

import { useTabs } from './TabsContext';
import { colours, layoutSizing } from '../constants/css';

const Container = styled.div`
  background: ${colours.lightGrey};
  display: flex;
  height: ${layoutSizing.tabs};
  width: 100%;
`;

const Tab = styled.div`
  min-width: 50px;
  ${(props) => props.isActive ? `
    background: white;
    border-radius: 8px 8px 0 0;
    margin: 3px;
    margin-bottom: 0;
    padding: 5px 10px 0;
  ` : `
    margin: 3px;
    padding: 8px 13px 0;
  `}

  a {
    text-decoration: none;
  }
`;

const DASHBOARD_TAB = { path: '/', text: 'Dashboard' };

const Tabs = () => {
  const { tabs, removeTab } = useTabs();

  const onClick = useCallback((path) => () => removeTab(path), [removeTab]);

  return (
    <Container>
      {[DASHBOARD_TAB, ...tabs].map(({ path, text }) => (
        <Tab key={text}>
          <NavLink activeStyle={{ color: '#333' }} to={path}>{text}</NavLink>
          {path !== DASHBOARD_TAB.path && <span onClick={onClick(path)}>&nbsp;x</span>}
        </Tab>
      ))}
    </Container>
  );
};

export default withRouter(Tabs);
