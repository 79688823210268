import React, { useState, useCallback } from 'react';
import styled from 'styled-components';

import { useAuth } from '../auth/auth0';
import ProgressCounter from '../common/ProgressCounter';
import IdentifyScript from '../scripts/ivaa/IdentifyScript';
import ConfirmPolicyScript from '../scripts/ivaa/ConfirmPolicyScript';

const IDENTIFY = 'identify';
const CONFIRM_POLICY = 'confirm-policy';
const EXPLAIN = 'explain';

const INTRODUCTION_STAGES = [
  {
    key: IDENTIFY,
    label: 'Identify the Person',
  },
  {
    key: CONFIRM_POLICY,
    label: 'Confirm Limits & Excess',
  },
  {
    key: EXPLAIN,
    label: 'Explain the Process',
  },
];

const Container = styled.div`
  height: 100%;
  width: 100%;
  background-color: white;
  margin-right: 5px;
  padding: 50px;
  padding-top: 30px;
  text-align: left;
`;

const Introduction = ({
  claim: {
    insured,
    insurer,
    location,
    ...claim
  },
  updateClaim,
}) => {
  const [currentStage, setCurrentStage] = useState(INTRODUCTION_STAGES[0]);
  const { me } = useAuth();

  const goToNextStage = useCallback(
    () => {
      const stages = INTRODUCTION_STAGES.map(({ key }) => key);

      setCurrentStage(INTRODUCTION_STAGES[stages.indexOf(currentStage.key) + 1]);
    },
    [currentStage],
  );

  return (
    <Container>
      <ProgressCounter
        current={currentStage}
        goToStage={setCurrentStage}
        stages={INTRODUCTION_STAGES}
      />
      {{
        [IDENTIFY]: (
          <IdentifyScript
            goToNextStage={goToNextStage}
            values={{
              insured,
              insurer,
              location,
              me,
            }}
          />
        ),
        [CONFIRM_POLICY]: (
          <ConfirmPolicyScript
            goToNextStage={goToNextStage}
            values={{
              claim,
              me,
              updateClaim,
            }}
          />
        ),
        [EXPLAIN]: <span>EXPLAIN</span>,
      }[currentStage.key]}
    </Container>
  );
};
export default Introduction;
