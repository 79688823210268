import React, { useState, useCallback } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faImages,
  // faMicrophone,
  faMicrophoneSlash,
  faPhoneSquareAlt,
} from '@fortawesome/free-solid-svg-icons';
// import VoiceToText from 'speech-to-text';

import ProgressCounter from '../common/ProgressCounter';
import IconContainer from '../common/styled/IconContainer';
import UploadThumbnail from '../common/styled/UploadThumbnail';
import { colours } from '../constants/css';
import DescriptionTable from '../items/DescriptionTable';

const DESCRIBE = 'describe';
const CONFIRM = 'confirm';
const QUOTE = 'quote';

const DESCRIPTION_STAGES = [
  {
    key: DESCRIBE,
    label: 'Describe Items',
  },
  {
    key: CONFIRM,
    label: 'Confirm Descriptions',
  },
  {
    key: QUOTE,
    label: 'Go to Quote',
  },
];

const Container = styled.div`
  height: 100%;
  width: 100%;
  background-color: white;
  margin-right: 5px;
  padding: 50px;
  padding-top: 30px;
  text-align: left;
`;

const PolContainer = styled.div`
  /* align-items: stretch; */
  background-color: ${colours.inactive};
  display: ${(props) => props.show ? 'block' : 'none'};
  height: 255px;
  margin-top: 10px;
  overflow-x: scroll;
  padding: 30px 0;
  white-space: nowrap;
  width: 100%;

  > a {
    margin: 0 15px;
  }

  > a:first-child {
    margin-left: 30px;
  }

  > a:last-child {
    margin-right: 30px;
  }
`;

const Descriptions = ({ claim: { limitTypes, uploads } }) => {
  const [currentStage, setCurrentStage] = useState(DESCRIPTION_STAGES[0]);

  const [showPol, setShowPol] = useState(false);
  const togglePol = useCallback((showing) => setShowPol(!showing), [setShowPol]);

  /*
  const [vtt, setVtt] = useState(() => {
    const makeNewEngine = () => new VoiceToText(
      (newContent) => setVtt((state) => ({
        ...state,
        content: `${state.content}\n${newContent}`,
        interim: '',
      })),
      () => setVtt((state) => ({ ...state, engine: makeNewEngine() })),
      (newInterimContent) => setVtt((state) => ({
        ...state,
        interim: newInterimContent,
      })),
      'en-AU',
    );

    return {
      active: false,
      content: '',
      engine: makeNewEngine(),
      interim: '',
    };
  });

  const vttRef = useRef(null);
  const toggleVtt = useCallback(() => {
    setVtt((state) => ({ ...state, active: !vtt.active }));
  }, [vtt, setVtt]);
 */

  /*
  useEffect(() => {
    vtt.active ? vtt.engine.startListening() : vtt.engine.stopListening();
  }, [vtt.active, vtt.engine]);

  useEffect(() => {
    if (vtt.content) {
      updateItem(vtt.content, 'description', activeIndex, true);
    }

    setVtt((state) => ({
      ...state,
      content: '',
      interim: '',
    }));
  }, [vtt.content, activeIndex, updateItem, setVtt]);
  */

  return (
    <Container>
      <ProgressCounter
        current={currentStage}
        goToStage={setCurrentStage}
        stages={DESCRIPTION_STAGES}
      />
      <IconContainer>
        <FontAwesomeIcon
          color={showPol ? colours.success : colours.inactive}
          icon={faImages}
          onClick={togglePol}
        />
        <FontAwesomeIcon
          // color={vtt.active ? colours.success : colours.inactive}
          color={colours.inactive}
          fixedWidth
          // icon={vtt.active ? faMicrophone : faMicrophoneSlash}
          icon={faMicrophoneSlash}
          // onClick={toggleVtt}
        />
        <FontAwesomeIcon
          color={colours.inactive}
          icon={faPhoneSquareAlt}
        />
      </IconContainer>
      <PolContainer show={showPol}>
        {uploads.map((upload) => (
          <UploadThumbnail key={upload.id} upload={upload} />
        ))}
      </PolContainer>
      {/* <p>interim text: <br />{vtt.interim}</p>
      <p>content:<br />{vtt.content}</p> */}
      <DescriptionTable limitTypes={limitTypes} />
    </Container>
  );
};

export default Descriptions;
