import React from 'react';
import { Redirect } from 'react-router-dom';

import Page from '../common/Page';
import { useAuth } from './auth0';

const Auth0Login = () => {
  const { isAuthenticated, loginWithPopup } = useAuth();

  return (
    <Page>
      {isAuthenticated
        ? <Redirect to="/" />
        : <button onClick={() => loginWithPopup({})} type="button">Log in</button>}
    </Page>
  );
};

export default Auth0Login;
