import React, { useState, useEffect, useContext, useCallback } from 'react';
import gql from 'graphql-tag';
import { allValid, getErrors, isTruthy } from '../utils/validators';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';

const ITEM_FIELDS_FRAGMENT = gql`
  fragment ItemFields on Item {
    id
    description
    descriptionClaimant
    purchaseDate
    purchaseDateClaimant
    purchaseLocation
    purchaseLocationClaimant
    purchasePrice
    purchasePriceClaimant
    itemLimit
    coverType
    approved
    approvedLiability
    quote
    notes
    notesClaimant
    rrp
    rrpClaimant
    wholesalePrice
    iscAmount
    iscAmountOverride
    serialNumber
    salvage
  }
`;

const GET_ITEMS = gql`
  query GetItems($claimId: Int!) {
    claimItems(claimId: $claimId) {
      ...ItemFields
    }
  }
  ${ITEM_FIELDS_FRAGMENT}
`;

const CREATE_ITEMS = gql`
  mutation CreateItems($newItems: [ItemInput!]!) {
    createItems(newItems: $newItems) {
      ...ItemFields
    }
  }
  ${ITEM_FIELDS_FRAGMENT}
`;

const UPDATE_ITEMS = gql`
 mutation UpdateItems($updatedItems: [ItemInput!]!) {
    updateItems(updated: $updatedItems) {
      ...ItemFields
    }
  }
  ${ITEM_FIELDS_FRAGMENT}
`;

const itemConditions = {
  coverType: isTruthy,
  description: isTruthy,
};

export const ItemsContext = React.createContext();
export const useItems = () => useContext(ItemsContext);
export const ItemsProvider = ({ children }) => {
  const [items, setItems] = useState();
  const [claimId, setClaimId] = useState(0);

  const [getClaimItems, { /* loading, error, */data }] = useLazyQuery(GET_ITEMS);

  const [createClaimItems] = useMutation(CREATE_ITEMS, {
    update: (cache, { data: { createItems } }) => {
      const { claimItems } = cache.readQuery({
        query: GET_ITEMS,
        variables: { claimId },
      });

      cache.writeQuery({
        data: { claimItems: [...claimItems, ...createItems] },
        query: GET_ITEMS,
      });
    },
  });
  const [updateClaimItems] = useMutation(UPDATE_ITEMS, {
    update: (cache, { data: { updateItems } }) => {
      const { claimItems } = cache.readQuery({
        query: GET_ITEMS,
        variables: { claimId },
      });
      const updatedById = updateItems.reduce((acc, item) => ({
        ...acc,
        [item.id]: item,
      }), {});

      cache.writeQuery({
        data: {
          claimItems: claimItems.map((item) => ({
            ...item,
            ...(updatedById[item.id] || {}),
          })),
        },
        query: GET_ITEMS,
      });
    },
  });

  const createItems = useCallback((newItems) => createClaimItems({
    variables: {
      newItems: newItems.map((item) => ({ claimId, ...item })),
    },
  }), [createClaimItems, claimId]);

  const updateItems = useCallback((updatedItems) => updateClaimItems({
    variables: {
      updatedItems: updatedItems.map((item) => ({ claimId, ...item })),
    },
  }), [claimId, updateClaimItems]);

  const clear = useCallback(() => {
    setClaimId(null);
    setItems(null);
  }, [setClaimId, setItems]);

  const getItemErrors = useCallback((item, field) => getErrors(itemConditions, item, field), []);

  const saveItems = useCallback((itemData, fields, setErrors) => {
    const allErrors = itemData.map((item) => getItemErrors(item));
    const allValidItems = allErrors.reduce((acc, itemErrors) => acc && allValid(itemErrors), true);

    if (!allValidItems) {
      return setErrors(allErrors);
    }

    const checkFields = fields.map(({ name }) => name);
    const { new: newItems, updated: updatedItems } = itemData.reduce((acc, item) => {
      const originalItem = items.find((original) => original.id === item.id);
      const updatedItem = checkFields.reduce((newItem, field) => {
        if (item.uuid || originalItem[field] !== item[field]) {
          return { ...newItem, [field]: item[field] };
        }

        return newItem;
      }, {});

      if (Object.keys(updatedItem).length) {
        if (item.uuid) {
          return { ...acc, new: [...acc.new, updatedItem] };
        }
        return { ...acc, updated: [...acc.updated, { id: originalItem.id, ...updatedItem }] };
      }

      return acc;
    }, { new: [], updated: [] });

    return [
      newItems.length && createItems(newItems),
      updatedItems.length && updateItems(updatedItems),
    ];
  }, [createItems, getItemErrors, items, updateItems]);

  useEffect(() => {
    if (claimId) {
      getClaimItems({ variables: { claimId } });
    }
  }, [claimId, getClaimItems]);

  useEffect(() => {
    setItems(data ? data.claimItems : []);
  }, [data, setItems]);

  return (
    <ItemsContext.Provider
      value={{
        clear,
        createItems,
        getItemErrors,
        items,
        saveItems,
        setClaimId,
        updateItems,
      }}
    >
      {children}
    </ItemsContext.Provider>
  );
};
