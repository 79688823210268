export const nameToAbbr = (fullName) => {
  const names = fullName.split(' ');

  if (names.length === 1) {
    return fullName.substr(0, Math.min(fullName.length, 4));
  }

  return names
    .map((name) => name[0])
    .join('')
    .substr(0, Math.min(names.length, 4));
};

export const currency = (amount = null) => {
  if (amount === null) {
    return '';
  }

  const options = { maximumFractionDigits: 2, minimumFractionDigits: 2 };

  return `$${Number(amount).toLocaleString(undefined, options)}`;
};

export const titleCase = (text) => (text || '').replace(/(^|\s)\S/gu, (char) => char.toUpperCase());
