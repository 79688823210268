import React from 'react';
import styled from 'styled-components';

import SummaryBox from '../common/styled/SummaryBox';

const Container = styled.div`
  height: 100%;
  width: 100%;
  background-color: white;
  margin-right: 5px;
  padding: 50px;
  padding-top: 30px;
  text-align: left;
`;

const Quoting = () => (
  <Container>
    <SummaryBox>
      Quoting
    </SummaryBox>
  </Container>
);

export default Quoting;
