import React from 'react';
import { NavLink } from 'react-router-dom';

import Page from '../common/Page';

const Suppliers = () => (
  <Page>
    <span>Suppliers</span>
    <NavLink to="/">Dash</NavLink>
  </Page>
);

export default Suppliers;
