/**
 * Generic type to which all check object properties should resolve.
 * @callback checkFunc
 * @param {any} val - Value of object to be checked
 * @returns {string} - Error message, empty string if no error found
 */

/**
 * Converts error object into boolean value.
 * @param {Object<string, string>} errors - Error results
 * @returns {Boolean} - Validation results
 */
export const allValid = (errors) => Object.values(errors)
  .reduce((acc, error) => acc && !error, true);

/**
 * Function closure that checks a condition.
 * @callback conditionFunc
 * @returns {Boolean} - Evaluation of condition
 */
/**
 * Wrapper to only invoke check function if some condition is true.
 * @param {conditionFunc} conditionFunc - Function to test if check is needed
 * @param {checkFunc} func - Function to check value
 * @returns {checkFunc} - Closure that conditionally invokes func
 */
export const conditional = (conditionFunc, func) => (val) => conditionFunc() && func(val);

/**
 * Converts a check object into an error object.
 * @param {Object<string, checkFunc>} funcs - Checks to be performed on each property
 * @param {Object<string, any>} values - Values that are to be checked
 * @param {string} [field] - If provided, only this field will be checked (useful for onChange)
 * @returns {Object<string, string>} - Object with error results
 */
export const getErrors = (funcs, values, field) => {
  if (field) {
    return { [field]: funcs[field] ? funcs[field](values[field]) : '' };
  }

  return Object.entries(funcs).reduce((acc, [key, func]) => ({
    ...acc,
    [key]: func(values[key]),
  }), {});
};

// eslint-disable-next-line valid-jsdoc
/**
 * Checks for truthy value.
 * @type {checkFunc}
 * */
export const isTruthy = (val) => val ? '' : 'Please enter a value';

/**
 * Wrapper to pass array of checks for a property.
 * @param {checkFunc[]} funcs - Array of checks
 * @returns {checkFunc} - Closure that sequentially invokes check functions
 */
export const multiple = (funcs) => (val) => funcs.reduce((error, func) => error || func(val), '');

/**
 * Wrapper to only run check if property has a truthy value.
 * @param {checkFunc} func - Function to check value
 * @returns {checkFunc} - Closure that only runs check if value is truthy
 */
export const optional = (func) => (val) => val ? func(val) : '';

