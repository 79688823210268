import React from 'react';
import styled from 'styled-components';
import { layoutSizing } from '../constants/css';

const Container = styled.div`
  height: calc(100vh - ${layoutSizing.header} - ${layoutSizing.tabs});
  overflow-y: scroll;
  position: relative;
  width: 100%;
`;

const Page = ({ children }) => (
  <Container>
    {children}
  </Container>
);

export default Page;
