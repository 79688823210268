import React, { useState, useEffect, useContext } from 'react';
import createAuthClient from '@auth0/auth0-spa-js';

const DEFAULT_REDIRECT_CALLBACK = () => window.history.replaceState(
  {},
  document.title,
  window.location.pathname,
);

export const AuthContext = React.createContext();
export const useAuth = () => useContext(AuthContext);
export const AuthProvider = ({
  children,
  onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
  ...initOptions
}) => {
  const [isAuthenticated, setIsAuthenticated] = useState();
  const [token, setToken] = useState();
  const [user, setUser] = useState();
  const [me, setMe] = useState();
  const [authClient, setAuth] = useState();
  const [loading, setLoading] = useState(true);
  const [popupOpen, setPopupOpen] = useState(false);

  const getIdToken = (client) => {
    try {
      return Object.values(client.cache.cache)[0].id_token;
    }
    catch {
      return null;
    }
  };

  useEffect(() => {
    const initAuth = async () => {
      const authFromHook = await createAuthClient(initOptions);
      setAuth(authFromHook);

      if (window.location.search.includes('code=')) {
        const { appState } = await authFromHook.handleRedirectCallback();
        onRedirectCallback(appState);
      }

      const isAlreadyAuthenticated = await authFromHook.isAuthenticated();

      setIsAuthenticated(isAlreadyAuthenticated);

      if (isAlreadyAuthenticated) {
        const cachedUser = await authFromHook.getUser();
        setUser(cachedUser);
        const cachedToken = getIdToken(authFromHook);
        setToken(cachedToken);
      }

      setLoading(false);
    };
    initAuth();
    // eslint-disable-next-line
  }, []);

  const loginWithPopup = async (params = {}) => {
    setPopupOpen(true);

    try {
      await authClient.loginWithPopup(params);
    }
    catch (error) {
      console.error(error);
    }
    finally {
      setPopupOpen(false);
    }
    const newUser = await authClient.getUser();
    const newToken = getIdToken(authClient);
    setUser(newUser);
    setToken(newToken);
    setIsAuthenticated(true);
  };

  const handleRedirectCallback = async () => {
    setLoading(true);
    await authClient.handleRedirectCallback();
    const newUser = await authClient.getUser();
    const newToken = getIdToken(authClient);
    setUser(newUser);
    setToken(newToken);
    setIsAuthenticated(true);
    setLoading(false);
  };

  const getIdTokenClaims = (...params) => authClient.getIdTokenClaims(...params);
  const getTokenSilently = (...params) => authClient.getTokenSilently(...params);
  const getTokenWithPopup = (...params) => authClient.getTokenWithPopup(...params);
  const ivaaLogin = (newMe) => setMe(newMe);
  const loginWithRedirect = (...params) => authClient.loginWithRedirect(...params);
  const logout = (...params) => authClient.logout(...params);

  return (
    <AuthContext.Provider
      value={{
        getIdTokenClaims,
        getTokenSilently,
        getTokenWithPopup,
        handleRedirectCallback,
        isAuthenticated,
        ivaaLogin,
        loading,
        loginWithPopup,
        loginWithRedirect,
        logout,
        me,
        popupOpen,
        token,
        user,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
