import React, { useCallback, useState } from 'react';
import styled from 'styled-components';

const TextWrap = styled.strong`
  cursor: pointer;
  text-decoration: underline;
`;

const ClickToEdit = ({
  formatter = (val) => val,
  initiallyOpen = false,
  save,
  type,
  value,
}) => {
  const [editable, setEditable] = useState(initiallyOpen);
  const [newValue, setNewValue] = useState(value);

  const toggleEditable = useCallback(
    () => setEditable(!editable),
    [editable],
  );

  const onCancel = useCallback(() => {
    setNewValue(value);
    toggleEditable();
  }, [setNewValue, value, toggleEditable]);

  const onChange = useCallback(({ target }) => {
    setNewValue(type === 'number' ? Number(target.value) : target.value);
  }, [type]);

  const onSave = useCallback(() => {
    save(newValue);
    toggleEditable();
  }, [save, toggleEditable, newValue]);

  return editable ? (
    <>
      <input onChange={onChange} type={type} value={newValue} />&nbsp;
      <TextWrap onClick={onSave}>Save</TextWrap>&nbsp;
      <TextWrap onClick={onCancel}>Cancel</TextWrap>
    </>
  ) : (
    <TextWrap onClick={toggleEditable}>{formatter(value)}*</TextWrap>
  );
};

export default ClickToEdit;
