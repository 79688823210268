import styled from 'styled-components';

const TextCircle = styled.div`
  width: ${(props) => props.diameter}px;
  height: ${(props) => props.diameter}px;
  border-radius: 50%;
  font-size: 1.5rem;
  color: #fff;
  line-height: ${(props) => props.diameter}px;
  text-align: center;
  background: ${(props) => props.backgroundColour};
  text-transform: uppercase;
`;

export default TextCircle;
