import React, { useContext, useState, useCallback } from 'react';

export const TabsContext = React.createContext();
export const useTabs = () => useContext(TabsContext);
export const TabsProvider = ({ children }) => {
  const [tabs, setTabs] = useState([]);

  const addTab = useCallback((newTab) => setTabs((currentTabs) => [
    ...currentTabs,
    ...(currentTabs.map(({ path }) => path).indexOf(newTab.path) > -1 ? [] : [newTab]),
  ]), []);
  const removeTab = useCallback(
    (path) => setTabs((currentTabs) => currentTabs.filter((tab) => tab.path !== path)),
    [],
  );

  return (
    <TabsContext.Provider
      value={{
        addTab,
        removeTab,
        tabs,
      }}
    >
      {children}
    </TabsContext.Provider>
  );
};
