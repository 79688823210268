import React from 'react';
import styled from 'styled-components';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

const Container = styled.div`
  ${(props) => props.incomplete ? 'max-width: 180px;' : ''}
  display: flex;
  align-items: flex-start;
`;

const Address = ({
  className,
  firstLine,
  location: {
    address,
    suburb,
    state,
    postcode,
    country,
    raw,
  },
}) => {
  const lines = [
    firstLine || address,
    suburb,
    (state || postcode) && `${state || ''}${state && postcode ? ', ' : ''}${postcode || ''}`,
    country,
  ].filter((line) => line);

  return lines.length < 4 ? (
    // eslint-disable-next-line react/forbid-component-props
    <Container className={className} incomplete>
      <p>{raw}</p>
      <OverlayTrigger overlay={<Tooltip><strong>Incomplete Address</strong></Tooltip>}>
        <FontAwesomeIcon icon={faInfoCircle} />
      </OverlayTrigger>
    </Container>
  ) : (
    // eslint-disable-next-line react/forbid-component-props
    <Container className={className}>
      <p>
        {lines.map((text, index) => (
          <React.Fragment key={text}>
            {Boolean(index) && <br />}
            <span>{text}</span>
          </React.Fragment>
        ))}
      </p>
    </Container>
  );
};

export default Address;
