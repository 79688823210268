import React from 'react';
import styled from 'styled-components';
import Button from 'react-bootstrap/Button';

const Container = styled.div`
  display: flex;
`;

const StyledButton = styled(Button)`
  margin: 5px 20px;
  min-width: 120px;
`;

const OptionButtons = ({ onClickFactory, options, variant = 'primary' }) => (
  <Container>
    {options.map(({ func, key, text }, index) => (
      <StyledButton
        // eslint-disable-next-line react/no-array-index-key
        key={`${key}-${index}`}
        onClick={onClickFactory(key, func)}
        variant={`${index ? 'outline-' : ''}${variant}`}
      >
        {text}
      </StyledButton>
    ))}
  </Container>
);

export default OptionButtons;
