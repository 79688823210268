import React from 'react';
import styled from 'styled-components';

import { colours } from '../../constants/css';

const Container = styled.div`
  border: 2px solid ${colours.darkGrey};
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
  padding: 10px;
  width: 100%;
`;

const SummaryBox = ({ children }) => (
  <Container>
    {children}
  </Container>
);

export default SummaryBox;
