import React from 'react';
import styled from 'styled-components';

const UploadLink = styled.a`
  height: 100%;
  margin: 0 10px;
`;

const UploadImg = styled.img`
  height: 100%;
`;

const UploadThumbnail = ({ upload: { filename, url, thumbnailUrl } }) => (
  <UploadLink href={url} rel="noopener noreferrer" target="_blank">
    <UploadImg alt={filename} src={thumbnailUrl} title={filename} />
  </UploadLink>
);

export default UploadThumbnail;
