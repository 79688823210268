import React, { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import { gql } from 'apollo-boost';
import Spinner from 'react-bootstrap/Spinner';

import { useAuth } from './auth/auth0';

import Header from './common/Header';
import Tabs from './common/Tabs';

import Auth0Login from './auth/Auth0Login';
import Claims from './claims/Claims';
import Dashboard from './dashboard';
import Suppliers from './suppliers';
import { useLazyQuery } from '@apollo/react-hooks';

const GET_ME = gql`
  query {
    me {
      id
      firstName
      surname
      name
      email
      phone
      active
      averageHours
      # roles
      # claimParameters
    }
  }
`;

const App = () => {
  const { loading: authLoading, ivaaLogin, me, user } = useAuth();
  const [getMe, { loading, error, data }] = useLazyQuery(GET_ME);

  useEffect(() => {
    if (!data && user) {
      getMe({ skip: me });
    }
  }, [data, getMe, me, user]);

  if (!me && data && data.me) {
    ivaaLogin(data.me);
  }

  if (authLoading || loading || !user) {
    return (
      <div
        style={{
          alignItems: 'center',
          display: 'flex',
          height: '100%',
          justifyContent: 'center',
          width: '100%',
        }}
      >
        {authLoading || loading || user
          ? <Spinner animation="grow" variant="primary" />
          : <Auth0Login />}
      </div>
    );
  }

  if (error) {
    return (
      <div>
        {`Error: ${JSON.stringify(error)}}`}
      </div>
    );
  }

  return (
    <div className="App">
      <Header />
      <Tabs />
      <Switch>
        <Route component={Dashboard} exact path="/" />
        <Route component={Suppliers} exact path="/suppliers" />
        <Route component={Claims} path="/claims" />
      </Switch>
    </div>
  );
};

export default App;
