import React, { useState, useCallback, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPlusCircle,
  faSave,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';
import uuid from 'uuid/v4';

import CoverTypeSelect from './CoverTypeSelect';
import { useItems } from './ItemsContext';
import SummaryBox from '../common/styled/SummaryBox';
import polTypes from '../constants/polTypes';
import { currency, titleCase } from '../utils/formatters';

const ButtonContainer = styled.div`
  > * {
    margin: 5px;
  }
`;

const SelectContainer = styled.div`
  display: flex;
  > * {
    margin: 5px;
  }
`;

const StyledTable = styled(Table)`
  overflow-x: scroll;
`;

const RED_CORNER = 'background-image: linear-gradient(225deg, red, red 7px, transparent 7px, transparent);';

const Cell = styled.td`
  ${(props) => props.noPadding ? 'padding: 0 !important;' : ''}
  ${(props) => props.hasError ? RED_CORNER : ''}
  height: ${(props) => props.height || 'auto'};
  width: ${(props) => props.width || 'auto'};

  > * {
    height: 100%;
    white-space: pre-wrap;
    resize: none;
    width: 100%;
  }
`;

/**
 * @typedef {Object} tableField - Description table fields
 * @property {Function} [tableField.formatter]
 * @property {string|React.Element} [tableField.Input]
 * @property {Function} [tableField.extraProps]
 * @property {string} tableField.name
 * @property {Boolean} tableField.removeRef
 * @property {string} tableField.title
 */
/** @type {tableField[]} */
const fields = [
  {
    Input: 'textarea',
    extraProps: (item) => ({ rows: (item.description || '').split('\n').length }),
    name: 'description',
    title: 'Description',
  },
  {
    name: 'purchaseDate',
    title: 'Purchase Date',
  },
  {
    name: 'purchaseLocation',
    title: 'Purchase Location',
  },
  {
    formatter: currency,
    name: 'purchasePrice',
    title: 'Purchase Price',
    type: 'number',
  },
  {
    Input: CoverTypeSelect,
    extraProps: (_, { limitTypes }) => ({
      limitTypes: limitTypes.filter((name) => name !== 'item'),
    }),
    formatter: titleCase,
    name: 'coverType',
    removeRef: true,
    title: 'Cover Type',
  },
  {
    formatter: currency,
    name: 'itemLimit',
    title: 'Item Limit',
    type: 'number',
  },
  {
    Input: 'textarea',
    name: 'notes',
    title: 'Notes',
  },
  {
    formatter: currency,
    name: 'rrp',
    title: 'Claimant Estimate',
    type: 'number',
  },
  {
    formatter: currency,
    name: 'iscAmountOverride',
    title: 'IVAA Estimate',
    type: 'number',
  },
];

/** @type {Object<string, string>} */
const polActions = {
  ALL: 'All',
  EMPTY: 'Empty Notes Fields',
  SELECTED: 'Selected',
};

const DescriptionTable = ({ limitTypes }) => {
  const { getItemErrors, items, saveItems } = useItems();

  const [itemData, setItemData] = useState(items);
  const [errors, setErrors] = useState(items.map(() => ({})));

  useEffect(() => {
    setItemData(items);
    setErrors(items.map(() => ({})));
  }, [items, setItemData]);

  const addItem = useCallback(() => {
    setItemData((state) => [...state, { uuid: uuid() }]);
    setErrors((state) => [...state, {}]);
  }, [setItemData]);

  const updateItem = useCallback((key, index, extend) => ({ target: { value } }) => {
    setItemData((oldItemData) => [
      ...oldItemData.slice(0, index),
      {
        ...oldItemData[index],
        [key]: extend && oldItemData[index][key]
          ? [oldItemData[index][key], value].join('\n')
          : value,
      },
      ...oldItemData.slice(index + 1),
    ]);
    setErrors((oldErrors) => [
      ...oldErrors.slice(0, index),
      {
        ...oldErrors[index],
        ...getItemErrors({ [key]: value }, key),
      },
      ...oldErrors.slice(index + 1),
    ]);
  }, [getItemErrors, setErrors, setItemData]);

  const save = useCallback(() => {
    saveItems(itemData, fields, setErrors);
  }, [itemData, saveItems, setErrors]);

  const [activeField, setActiveField] = useState({});
  const activeFieldRef = useRef(null);

  const resetActiveField = useCallback(() => setActiveField({}), [setActiveField]);
  const updateActiveField = useCallback((field) => () => setActiveField(field), [setActiveField]);

  useEffect(() => {
    activeFieldRef.current && activeFieldRef.current.focus();
  });

  const [selected, setSelected] = useState([]);

  const toggleItemSelection = useCallback((itemId) => () => {
    setSelected((currentSelection) => {
      const index = currentSelection.indexOf(itemId);

      return index > -1
        ? [...currentSelection.slice(0, index), ...currentSelection.slice(index + 1)]
        : [...currentSelection, itemId];
    });
  }, []);
  const toggleSelectAll = useCallback(() => {
    setSelected((currentSelection) => currentSelection.length === itemData.length
      ? []
      : itemData.map((item) => item.id || item.uuid));
  }, [itemData, setSelected]);

  const [selectedPolType, setSelectedPolType] = useState('');
  const [selectedPolAction, setSelectedPolAction] = useState('');

  const updatePolType = useCallback(({ target: { value } }) => {
    setSelectedPolType(value);
  }, [setSelectedPolType]);
  const updatePolAction = useCallback(({ target: { value } }) => {
    setSelectedPolAction(value);
  }, [setSelectedPolAction]);

  const applyPolAction = useCallback(() => {
    itemData.forEach((item, index) => {
      const shouldUpdate = {
        [polActions.ALL]: true,
        [polActions.EMPTY]: !item.notes,
        [polActions.SELECTED]: selected.indexOf(item.id || item.uuid) > -1,
      };
      if (shouldUpdate[selectedPolAction]) {
        updateItem('notes', index, true)({ target: { value: selectedPolType } });
      }
    });
    setSelectedPolType('');
    setSelectedPolAction('');
  }, [itemData, selected, selectedPolAction, selectedPolType, updateItem]);

  const extraPropsData = { limitTypes };

  return (
    <>
      <SummaryBox>
        <div>
          <ButtonContainer>
            <Button onClick={addItem} size="lg" variant="outline-success">
              <FontAwesomeIcon icon={faPlusCircle} />
              &nbsp;
              <span>New Item</span>
            </Button>
            <Button onClick={save} size="lg" variant="outline-primary">
              <FontAwesomeIcon icon={faSave} />
              &nbsp;
              <span>Save</span>
            </Button>
            <Button disabled={false} size="lg" variant="outline-danger">
              <FontAwesomeIcon icon={faTrash} />
              &nbsp;
              <span>Delete</span>
            </Button>
          </ButtonContainer>
          <SelectContainer>
            <Form.Control
              as="select"
              onChange={updatePolType}
              value={selectedPolType}
            >
              <option value="">Proof of Loss Type</option>
              {polTypes.map((polType) => (
                <option key={polType} value={polType}>{polType}</option>
              ))}
            </Form.Control>
            <Form.Control
              as="select"
              disabled={!selectedPolType}
              onChange={updatePolAction}
              value={selectedPolAction}
            >
              <option value="">Apply To</option>
              {Object.values(polActions).map((polAction) => (
                <option key={polAction} value={polAction}>{polAction}</option>
              ))}
            </Form.Control>
            <Button
              disabled={!selectedPolAction}
              onClick={applyPolAction}
              variant="outline-info"
            >
              Apply
            </Button>
          </SelectContainer>
        </div>
        <div>
          send buttons
        </div>
      </SummaryBox>

      <StyledTable bordered>
        <thead>
          <tr>
            <th>
              <Form.Check
                aria-label="select-all"
                checked={selected.length === itemData.length}
                onChange={toggleSelectAll}
                type="checkbox"
              />
            </th>
            {fields.map(({ title }) => <th key={title}>{title}</th>)}
          </tr>
        </thead>
        <tbody>
          {itemData.map((item, index) => (
            <tr key={item.id || item.uuid}>
              <Cell>
                <Form.Check
                  aria-label={`checkbox-${item.id || item.uuid}`}
                  checked={selected.indexOf(item.id || item.uuid) > -1}
                  onChange={toggleItemSelection(item.id || item.uuid)}
                  type="checkbox"
                />
              </Cell>
              {fields.map(({
                Input = 'input',
                extraProps = () => ({}),
                formatter = (val) => val,
                name,
                removeRef,
                type = 'text',
              }) => (
                <React.Fragment key={`${item.id || item.uuid}-${name}`}>
                  {name === activeField.name && index === activeField.index ? (
                    <Cell noPadding>
                      <Input
                        onBlur={resetActiveField}
                        onChange={updateItem(name, index)}
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...(removeRef ? {} : { ref: activeFieldRef })}
                        type={type}
                        value={item[name] || ''}
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...extraProps(item, extraPropsData)}
                      />
                    </Cell>
                  ) : (
                    <Cell
                      hasError={errors[index][name]}
                      onClick={updateActiveField({ index, name })}
                    >
                      <div>
                        {formatter(item[name])}
                        &nbsp;
                      </div>
                    </Cell>
                  )}
                </React.Fragment>
              ))}
            </tr>
          ))}
        </tbody>
      </StyledTable>
    </>
  );
};

export default DescriptionTable;
