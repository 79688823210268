import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import Spinner from 'react-bootstrap/Spinner';
import Table from 'react-bootstrap/Table';

import { useAuth } from '../auth/auth0';

import Links from '../common/Links';

const WORKLOAD = gql`
  query Workload($userId: Int) {
    workload(userId: $userId) {
      id
      claimType {
        title
        order
      }
      insured {
        name
      }
      insurer {
        name
      }
      status {
        id
        name
        description
        colour
        order
      }
    }
  }
`;

const ClaimList = () => {
  const { me } = useAuth();
  const { loading, error, data } = useQuery(WORKLOAD, {
    skip: !me,
  });

  if (loading || !me) {
    return <Spinner animation="border" variant="primary" />;
  }

  if (error) {
    return (
      <>
        {JSON.stringify(error)}
      </>
    );
  }

  const claimsByStatus = data.workload.reduce((acc, claim) => ({
    ...acc,
    [claim.status.id]: [...(acc[claim.status.id] || []), claim],
  }), {});

  const orderedStatuses = Object.values(claimsByStatus)
    .map(([{ status }]) => status)
    .sort(({ order: orderA }, { order: orderB }) => orderB - orderA);

  return (
    <Table bordered>
      <thead>
        <tr>
          <th>Status</th>
          <th>Type</th>
          <th>Claim ID</th>
          <th>Name</th>
          <th>Insurer</th>
          <th>Stats</th>
        </tr>
      </thead>
      <tbody>
        {orderedStatuses.map((status) => claimsByStatus[status.id].map((claim, idx) => (
          <tr key={claim.id}>
            {!idx && (
              <td rowSpan={claimsByStatus[status.id].length}>
                {status.name}
              </td>
            )}
            <td>
              {claim.claimType.title}
            </td>
            <td>
              <Links.Claim claimId={claim.id} />
            </td>
            <td>
              {claim.insured.name}
            </td>
            <td>
              {claim.insurer.name}
            </td>
            <td />
          </tr>
        )))}
      </tbody>
    </Table>
  );
};

export default ClaimList;
