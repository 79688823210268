import React, { useState, useCallback, useEffect } from 'react';
import gql from 'graphql-tag';
import { useQuery, useMutation } from '@apollo/react-hooks';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Spinner from 'react-bootstrap/Spinner';
import { NavLink, Redirect, Route, Switch } from 'react-router-dom';
import styled from 'styled-components';

import StatusSelect from '../common/StatusSelect';
import DateSelect from '../common/DateSelect';

import Contact from './Contact';
import Introduction from './Introduction';
import Descriptions from './Descriptions';
import InsurerReview from './InsurerReview';
import Quoting from './Quoting';
import SelectSuppliers from './SelectSuppliers';
import { colours } from '../constants/css';
import { useItems } from '../items/ItemsContext';

const CLAIM_FRAGMENT = gql`
  fragment ClaimFields on Claim {
    id
    actionDate
    excess
    limits
    claimType {
      title
    }
    uploads {
      id
      filename
      url
      thumbnailUrl
    }
    insured {
      firstName
      surname
      name
      home
      work
      mobile
      email
      preferredContactMethod
    }
    location {
      address
      suburb
      state
      postcode
      country
      raw
    }
    contacts {
      id
      firstName
      surname
      company
      home
      work
      mobile
      email
      type
      isPrimary
      preferredContactMethod
      notes
    }
    insurer {
      name
      shortName
      phone
      email
      categoryManagerFirstName
      categoryManagerSurname
      categoryManagerEmail
    }
    status {
      id
      name
      description
      colour
      order
    }
  }
`;

const GET_CLAIM = gql`
  query GetClaim($claimId: Int!) {
    claim(id: $claimId) {
      ...ClaimFields
    }
  }
  ${CLAIM_FRAGMENT}
`;

const UPDATE_CLAIM = gql`
  mutation UpdateClaim($claimId: Int!, $input: ClaimInput!) {
    updateClaim(id: $claimId, input: $input) {
      ...ClaimFields
    }
  }
  ${CLAIM_FRAGMENT}
`;

const ClaimHeader = styled.div`
  display: flex;
  height: 100px;
  justify-content: space-between;
  padding: 8px;
  width: 100%;
`;

const Dropdowns = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
`;

const Container = styled.div`
  display: flex;
  background-color: ${colours.claimBackground};
  height: 100%;
`;

const Main = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-width: 75%;
`;

const SideBar = styled.div`
  width: 25%;
`;

const ARROW = {
  HEIGHT: 40,
  WIDTH: 170,
};

const StageArrows = styled.div`
  display: flex;
  width: 100%;
  height: ${ARROW.HEIGHT * 3 / 2}px;
`;

const Arrow = styled.div`
  border: ${ARROW.HEIGHT / 2}px solid ${(props) => props.colour};
  border-left: ${ARROW.HEIGHT / 2}px solid ${(props) => props.first ? props.colour : 'transparent'};
  position: relative;
  width: 200px;
  height: ${ARROW.HEIGHT}px;
  margin-right: 5px;

  > a {
    position: absolute;
    font-size: 1.2rem;
    font-weight: 500;
    top: -10px;
    left: calc(34% - ${(props) => props.pxOffset}px);
    color: white;
    text-decoration: none;
  }

  > .right-point {
    position: absolute;
    height: 0;
    width: 0;
    top: -${ARROW.HEIGHT / 2}px;
    right: -${ARROW.HEIGHT}px;
    border-top: ${ARROW.HEIGHT / 2}px solid transparent;
    border-bottom: ${ARROW.HEIGHT / 2}px solid transparent;
    border-left: ${ARROW.HEIGHT / 2}px solid ${(props) => props.colour};
  }
`;

const Pointer = styled.div`
  border-top: none;
  border-left: ${ARROW.HEIGHT / 2}px solid transparent;
  border-right: ${ARROW.HEIGHT / 2}px solid transparent;
  border-bottom: ${ARROW.HEIGHT / 2}px solid white;
  bottom: -${ARROW.HEIGHT / 2}px;
  height: 0;
  left: 50%;
  position: relative;
  width: 0;
  z-index: 10;
`;

const STAGES = [
  {
    path: 'introduction',
    pxOffset: -3,
    text: 'Introduction',
  },
  {
    path: 'description',
    pxOffset: -5,
    text: 'Description',
  },
  {
    path: 'quoting',
    pxOffset: -15,
    text: 'Quoting',
  },
  {
    path: 'insurer-review',
    pxOffset: 6,
    text: 'Insurer Review',
  },
  {
    path: 'select-suppliers',
    pxOffset: 12,
    text: 'Select Suppliers',
  },
];

const Claim = ({ location, match }) => {
  const claimId = Number(match.params.claimId);

  const { loading, error, data } = useQuery(GET_CLAIM, { variables: { claimId } });

  const [updateClaimRaw] = useMutation(UPDATE_CLAIM);
  const updateClaim = useCallback((claimData) => updateClaimRaw({
    variables: {
      claimId,
      input: claimData,
    },
  }), [updateClaimRaw, claimId]);

  const { setClaimId } = useItems();

  useEffect(() => {
    setClaimId(claimId);
  }, [claimId, setClaimId]);

  const [actionDate, setActionDate] = useState();
  const [status, setStatus] = useState();
  // const [showSidebar, setShowSidebar] = useState(true);

  // const toggleSidebar = useCallback(
  //   () => setShowSidebar(!showSidebar),
  //   [showSidebar, setShowSidebar]
  // );

  if (loading) {
    return <Spinner animation="border" variant="primary" />;
  }

  if (error) {
    return (
      <pre>
        {JSON.stringify(error)}
      </pre>
    );
  }

  const { claim } = data;
  const contacts = [
    {
      ...claim.insured,
      isPrimary: !claim.contacts.find(({ isPrimary }) => isPrimary),
      location: claim.location,
      type: 'INSURED',
    },
    ...claim.contacts,
    {
      company: claim.insurer.shortName,
      email: claim.insurer.categoryManagerEmail || claim.insurer.email,
      firstName: claim.insurer.categoryManagerFirstName,
      surname: claim.insurer.categoryManagerSurname,
      type: 'INSURER',
      work: claim.insurer.phone,
    },
  ];

  const currentStageIndex = STAGES
    .map(({ path }) => path)
    .indexOf(location.pathname.split('/').reverse()[0]);

  const getColour = (index) => {
    if (currentStageIndex === index) {
      return colours.success;
    }
    else if (currentStageIndex < index) {
      return colours.faintBlue;
    }

    return colours.successInactive;
  };

  if (!status) {
    setStatus(claim.status);
  }
  if (!actionDate) {
    setActionDate(new Date(Number(claim.actionDate)));
  }

  const routeProps = {
    claim: {
      ...claim,
      limitTypes: Object.keys(claim.limits),
    },
    updateClaim,
  };

  return (
    <div style={{ height: '100%' }}>
      <ClaimHeader>
        <Dropdowns>
          <Form.Group as={Row}>
            <Form.Label column sm="2">
              Status:
            </Form.Label>
            <Col sm="10">
              <StatusSelect currentStatus={status} selectStatus={setStatus} />
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column sm="2">
              Action Date:
            </Form.Label>
            <Col sm="10">
              <DateSelect currentDate={actionDate} selectDate={setActionDate} />
            </Col>
          </Form.Group>
        </Dropdowns>
        {contacts.map((contact, i) => <Contact contact={contact} key={contact.id || i} />)}
      </ClaimHeader>
      <Container>
        <Main>
          <StageArrows>
            {STAGES.map(({ pxOffset, path, text }, index) => (
              <Arrow
                colour={getColour(index)}
                first={!index}
                key={path}
                pxOffset={pxOffset}
              >
                <NavLink to={path}>{text}</NavLink>
                <div className="right-point" />
                {currentStageIndex === index && <Pointer />}
              </Arrow>
            ))}
          </StageArrows>
          {/* eslint-disable react/jsx-no-bind, react/jsx-props-no-spreading */}
          <Switch>
            <Route
              exact
              path={`${match.path}/introduction`}
              render={(props) => <Introduction {...routeProps} {...props} />}
            />
            <Route
              exact
              path={`${match.path}/description`}
              render={(props) => <Descriptions {...routeProps} {...props} />}
            />
            <Route
              exact
              path={`${match.path}/quoting`}
              render={(props) => <Quoting {...routeProps} {...props} />}
            />
            <Route
              exact
              path={`${match.path}/insurer-review`}
              render={(props) => <InsurerReview {...routeProps} {...props} />}
            />
            <Route
              exact
              path={`${match.path}/select-suppliers`}
              render={(props) => <SelectSuppliers {...routeProps} {...props} />}
            />
            <Route render={() => <Redirect to={`${match.url}/introduction`} />} />
          </Switch>
        </Main>
        <SideBar>
          This muh sidebar
        </SideBar>
      </Container>
    </div>
  );
};

export default Claim;
